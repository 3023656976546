import axios from 'axios'

const baseURL = process.env.VUE_APP_DROP_ORDER_SERVICE

export default {
  namespaced: true,
  state: {
    isLoaded: false,
    order_status_list: [],
    delivery_status_list: [],
    pay_status_list: [],
    withdraw_status_list: [],
    user_status_list: [],
    //
    pay_method_list: [],
    delivery_pay_method_list: [],
    delivery_type_list: [],
    self_ttn_type_list: []
  },
  mutations: {
    setConfig (state, payload) {
      state.order_status_list = payload.order_status_list
      state.delivery_status_list = payload.delivery_status_list
      state.pay_status_list = payload.pay_status_list
      state.withdraw_status_list = payload.withdraw_status_list
      state.pay_method_list = payload.pay_method_list
      state.delivery_pay_method_list = payload.delivery_pay_method_list_all
      state.delivery_type_list = payload.delivery_type_list
      state.self_ttn_type_list = payload.self_ttn_type_list
      state.user_status_list = payload.user_status_list
      state.isLoaded = true
    }
  },
  actions: {
    getConfig ({ commit }) {
      axios.get(`${baseURL}/api/v1/config`).then(res => {
        commit('setConfig', res.data.data)
      })
    }
  }
}
