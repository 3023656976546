import axios from 'axios'
import i18n from '@/i18n'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/products'

export default {
  namespaced: true,
  state: {
    image_url: process.env.VUE_APP_IMAGE_SERVER,
    is_load_products: false,
    is_update_images: false,
    list_per_page: [10, 20, 30, 50, 100],
    products: [],
    product: null,
    product_pagination: {
      current_page: 1,
      per_page: 10,
      total: 0
    },
    product_filters: {
      search_sort: false,
      sorting: localStorage.getItem('etalon-admin-products-sort') || 5,
      is_cylinder_description: false,
      is_related_cylinder: null,
      no_rz_id: false,
      no_drop_category_id: false,
      no_cylinder_category_id: false,
      is_not_exportable: false,
      is_not_vendor: false,
      is_not_country: false,
      is_not_type: false,
      search: null,
      order_by: 'code',
      order_direction: 'asc',
      in_stock: localStorage.getItem('etalon-admin-in-stock') === 'true'
    },
    category_id: null,
    breadcrumbs: [],
    selected_products: []
  },
  getters: {
    selected_ids (state) {
      const ids = []
      state.selected_products.forEach(item => {
        ids.push(item.id)
      })
      return ids
    }
  },
  mutations: {
    setLoadProducts (state, value) {
      state.is_load_products = value === true
    },
    setUpdateImages (state, value) {
      state.is_update_images = value === true
    },
    setProducts (state, payload) {
      state.products = payload
    },
    setProductPagination (state, payload) {
      state.product_pagination = payload
    },
    setProductPage (state, payload) {
      state.product_pagination.current_page = payload
    },
    setProductPerPage (state, payload) {
      state.product_pagination.per_page = payload
      localStorage.setItem('etalon-admin-products-per-page', payload)
    },
    setCategoryId (state, payload) {
      state.category_id = payload
    },
    setProduct (state, payload) {
      state.product = payload
    },
    setProductSearch (state, payload) {
      state.product_filters.search = payload
    },
    setInStock (state, v) {
      state.product_filters.in_stock = v
      localStorage.setItem('etalon-admin-in-stock', v)
    },
    setIsCylinderDescription (state, v) {
      state.product_filters.is_cylinder_description = v
    },
    setIsRelatedCylinder (state, v) {
      state.product_filters.is_related_cylinder = v
    },
    setNoRzId (state, v) {
      state.product_filters.no_rz_id = v
    },
    setNoDropCategoryId (state, v) {
      state.product_filters.no_drop_category_id = v
    },
    setNoCylinderCategoryId (state, v) {
      state.product_filters.no_cylinder_category_id = v
    },
    setIsNotExportable (state, v) {
      state.product_filters.is_not_exportable = v
    },
    setIsNotVendor (state, v) {
      state.product_filters.is_not_vendor = v
    },
    setIsNotCountry (state, v) {
      state.product_filters.is_not_country = v
    },
    setIsNotType (state, v) {
      state.product_filters.is_not_type = v
    },
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    },
    setSorting (state, v) {
      if (v === 99) {
        state.product_filters.search_sort = true
        return
      }
      state.product_filters.sorting = v
      state.product_filters.search_sort = false
      localStorage.setItem('etalon-admin-products-sort', v)
    },
    setSelectedProducts (state, data) {
      state.selected_products = data
    },
    setAnalogueCountInProduct (state, payload) {
      const index = state.products.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.products[index].analogues_count = payload.count
      }

      state.products = [...state.products]
    }
  },
  actions: {
    async getProducts ({
      state,
      commit
    }) {
      commit('setLoadProducts', true)
      let isRelated = null

      if (state.product_filters.is_related_cylinder === true || state.product_filters.is_related_cylinder === false) {
        isRelated = state.product_filters.is_related_cylinder === true ? 1 : 0
      }

      if (!state.product_filters.search_sort) {
        switch (parseInt(state.product_filters.sorting)) {
          case 1:
            state.product_filters.order_by = 'price'
            state.product_filters.order_direction = 'asc'
            break
          case 2:
            state.product_filters.order_by = 'price'
            state.product_filters.order_direction = 'desc'
            break
          case 3:
            state.product_filters.order_by = 'name'
            if (i18n.locale === 'ua') {
              state.product_filters.order_by = 'name_uk'
            }
            state.product_filters.order_direction = 'asc'
            break
          case 4:
            state.product_filters.order_by = 'name'
            if (i18n.locale === 'ua') {
              state.product_filters.order_by = 'name_uk'
            }
            state.product_filters.order_direction = 'desc'
            break
          case 5:
            state.product_filters.order_by = 'code'
            state.product_filters.order_direction = 'asc'
            break
          case 6:
            state.product_filters.order_by = 'code'
            state.product_filters.order_direction = 'desc'
            break
          case 7:
            state.product_filters.order_by = 'has_images'
            state.product_filters.order_direction = 'asc'
            break
          case 8:
            state.product_filters.order_by = 'has_images'
            state.product_filters.order_direction = 'desc'
            break
          case 9:
            state.product_filters.order_by = 'vendor_code'
            state.product_filters.order_direction = 'asc'
            break
          case 10:
            state.product_filters.order_by = 'vendor_code'
            state.product_filters.order_direction = 'desc'
            break
          case 11:
            state.product_filters.order_by = 'qty'
            state.product_filters.order_direction = 'asc'
            break
          case 12:
            state.product_filters.order_by = 'qty'
            state.product_filters.order_direction = 'desc'
            break
          case 13:
            state.product_filters.order_by = 'created_at'
            state.product_filters.order_direction = 'asc'
            break
          case 14:
            state.product_filters.order_by = 'created_at'
            state.product_filters.order_direction = 'desc'
            break
        }
      }

      const promise = axios.get(baseURL, {
        params: {
          category_id: state.category_id,
          page: state.product_pagination.current_page,
          per_page: state.product_pagination.per_page,
          search: state.product_filters.search,
          order_by: state.product_filters.order_by,
          order_direction: state.product_filters.order_direction,
          in_stock: state.product_filters.in_stock === true ? 1 : 0,
          is_cylinder_description: state.product_filters.is_cylinder_description === true ? 1 : 0,
          no_rz_id: state.product_filters.no_rz_id === true ? 1 : 0,
          no_drop_category_id: state.product_filters.no_drop_category_id === true ? 1 : 0,
          no_cylinder_category_id: state.product_filters.no_cylinder_category_id === true ? 1 : 0,
          is_not_exportable: state.product_filters.is_not_exportable === true ? 1 : 0,
          is_not_vendor: state.product_filters.is_not_vendor === true ? 1 : 0,
          is_not_country: state.product_filters.is_not_country === true ? 1 : 0,
          is_not_type: state.product_filters.is_not_type === true ? 1 : 0,
          is_related_cylinder: isRelated
        }
      })
      promise.then(response => {
        commit('setProducts', response.data.data)
        commit('setProductPagination', response.data.meta)
        commit('setLoadProducts', false)
        commit('setBreadcrumbs', response.data.breadcrumbs)
        commit('setSelectedProducts', [])
      }).catch(e => {
        commit('setLoadProducts', false)
      })
      return promise
    },
    async getProduct ({
      state,
      commit
    }, id) {
      commit('setLoadProducts', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setProduct', response.data.data)
        commit('setLoadProducts', false)
      }).catch(e => {
        commit('setLoadProducts', false)
      })
      return promise
    },
    async uploadImages ({
      state,
      commit,
      dispatch
    }, data) {
      commit('setUpdateImages', true)
      const formData = new FormData()

      for (let i = 0; i < data.images.length; i++) {
        formData.append('files[' + i + ']', data.images[i])
      }

      const promise = axios.post(baseURL + '/' + data.id + '/upload', formData)
      promise.then(response => {
        commit('setUpdateImages', false)
        dispatch('getProduct', data.id)
      }).catch(e => {
        commit('setUpdateImages', false)
      })
      return promise
    },
    async deleteImage ({
      state,
      commit,
      dispatch
    }, data) {
      commit('setUpdateImages', true)
      const promise = axios.post(baseURL + '/' + data.id + '/delete', data)
      promise.then(response => {
        commit('setUpdateImages', false)
        dispatch('getProduct', data.id)
      }).catch(e => {
        commit('setUpdateImages', false)
      })
      return promise
    },
    async titleImage ({
      state,
      commit,
      dispatch
    }, data) {
      commit('setUpdateImages', true)
      const promise = axios.post(baseURL + '/' + data.id + '/title', data)
      promise.then(response => {
        commit('setUpdateImages', false)
        dispatch('getProduct', data.id)
      }).catch(e => {
        commit('setUpdateImages', false)
      })
      return promise
    },
    async updateProduct ({
      state,
      commit,
      dispatch
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)
      return promise
    },
    async updateRozetkaDataProduct ({
      state,
      commit,
      dispatch
    }, data) {
      return axios.put(baseURL + '/' + data.id + '/update-rozetka', data)
    },
    async updateProductsTags ({
      state,
      commit,
      dispatch
    }, data) {
      return axios.put(baseURL + '/add-tags', data)
    },
    async updateProductsDropCategory ({
      state,
      commit,
      dispatch
    }, data) {
      return axios.put(baseURL + '/set-drop-category', data)
    },
    async updateProductsCylinderCategory ({
      state,
      commit,
      dispatch
    }, data) {
      return axios.put(baseURL + '/set-cylinder-category', data)
    },
    updateUnpack ({ state }, data) {
      return axios.put(baseURL + '/unpack', data)
    }
  }
}
